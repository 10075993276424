/* dm-sans-regular - latin */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	src: url('dm-sans-v6-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('dm-sans-v6-latin-regulard41d.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('dm-sans-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('dm-sans-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('dm-sans-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('dm-sans-v6-latin-regular.svg') format('svg'); /* Legacy iOS */
  }
  /* dm-sans-500 - latin */
  @font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	src: url('dm-sans-v6-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('dm-sans-v6-latin-500d41d.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('dm-sans-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('dm-sans-v6-latin-500.woff') format('woff'), /* Modern Browsers */
		 url('dm-sans-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('dm-sans-v6-latin-500.svg#DMSans') format('svg'); /* Legacy iOS */
  }
  /* dm-sans-700 - latin */
  @font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	src: url('dm-sans-v6-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('dm-sans-v6-latin-700d41d.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('dm-sans-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('dm-sans-v6-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('dm-sans-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('dm-sans-v6-latin-700.svg#DMSans') format('svg'); /* Legacy iOS */
  }