
/* Fonts */
@import url('fonts/dm-sans/dm-sans.css');
@import url('fonts/gilroy/Gilroy.css');
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/dm-sans/dm-sans-v6-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('fonts/dm-sans/dm-sans-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('fonts/dm-sans/dm-sans-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('fonts/dm-sans/dm-sans-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('fonts/dm-sans/dm-sans-v6-latin-regular.svg') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Gilroy';
	src: url('fonts/gilroy/Gilroy-Extrabold.eot');
	src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
		url('fonts/gilroy/Gilroy-Extraboldd41d.eot?#iefix') format('embedded-opentype'),
		url('fonts/gilroy/Gilroy-Extrabold.woff') format('woff'),
		url('fonts/gilroy/Gilroy-Extrabold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
:root{
    --primary: #ff8000;
    --primaryHover: #c36100;
}
/* Body */
body {
    font-size: 13px;
    font-family: 'DM Sans', sans-serif !important;
    color: #6c757d;
    overflow-x: hidden;
    background-color: #ececec !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: 'Gilroy', sans-serif !important;
}
.btn-primary{
    background-color: #ff8000 !important;
    border: 1px solid #ff8000 !important;
}
.text-primary {
    color: #ff8000 !important;
}
.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active{
    color: #fff !important;
    background-color: #ff8000 !important;
    border-color: #ff8000 !important;
}
.btn-outline-primary {
    color: #ff8000 !important;
    border-color: #ff8000 !important;
}
.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #ff8000 !important;
    border-color: #ff8000 !important;
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
    font-size: 12px;
    font-weight: 600;
}
/* FAQ */
.faq-page .accordion-button {
    font-size: 14px;
}
.feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    font-size: 1.5rem;
}

/* Slider */
.slick-track{
    display:flex;
}
.slick-slide{
    display:flex;
    justify-content:center;
}

.trail-slider .slick-dots {
    bottom: -5px !important;
}

.slick-slider {
    margin-bottom: 0px !important;
}
.slick-dots {
    bottom: -10px !important;
}
.slick-arrow {
    background: #fff !important;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    box-shadow: 0 .5px 10px rgba(0,0,0,.20)!important;
    z-index: 9;
}
.slick-arrow:hover, .slick-arrow:focus {
    background: #6c757d !important;
}
.slick-arrow:hover:before, .slick-arrow:focus:before {
    color: #ffffff !important;
}
.slick-prev:before, .slick-next:before {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}
/* .slick-next {
    right: 15px;
}
.slick-prev {
    left: 15px !important;
} */
.slick-prev:before {
    content: "\eac9" !important;
    font-family: IcoFont!important;
    color: #333 !important;
}
.slick-next:before {
    content: "\eaca" !important;
    font-family: IcoFont!important;
    color: #333 !important;
}


::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}
::-webkit-scrollbar-corner {
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	height: 6px;
	border: 4px solid transparent;
	background-clip: padding-box;
	-webkit-border-radius: 7px;
	border-radius: 7px;
	background-color: rgba(0, 0, 0, .15);
	-webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, .05), inset 1px 1px 0 rgba(0, 0, 0, .05);
	box-shadow: inset -1px -1px 0 rgba(0, 0, 0, .05), inset 1px 1px 0 rgba(0, 0, 0, .05);
}
::-webkit-scrollbar {
	width: 4px;
	background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
	height: 50px;
	background: hsla(0, 0%, 53.3%, 0.4);
}

/* Basic Css */
.dropdown-menu.fs-13.dropdown-menu-end {
    padding: 6px 6px;
    border-radius: 8px;
}

.dropdown-menu.fs-13.dropdown-menu-end .dropdown-item {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border-radius: 6px;
}

.dropdown-menu.fs-13.dropdown-menu-end .dropdown-item span {
    margin: 0 8px 0 0 !important;
}
.bg-light {
    background-color: #edf2f6 !important;
}
.small {
    font-size: .875em !important;
}
.fs-3 {
    font-size: 3px !important;
}
.fs-13 {
    font-size: 13px !important;
}
.fs-11 {
    font-size: 11px !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.ln-0 {
    line-height: 0px !important;
}
.ln-18 {
    line-height: 18px;
}
.ln-25 {
    line-height: 25px !important;
}
.rounded-4 {
    border-radius: 14px;
}
.rounded-5{
    border-radius: 8px;
}
.form-control:focus {
    border-color: none;
    box-shadow: none;
}
.bg-warning-100 {
    background-color: #f6f3e6;
}
.fix-sidebar {
    position: sticky;
    top: 20px;
}
.sticky-sidebar2 {
    position: sticky;
    top: 20px;
}
.rotate-320 {
    transform: rotate(320deg);
}
.form-check-input:checked {
    background-color: #ff8000;
    border-color: #ff8000;
}
.chat-text {
    padding: 9px 13px !important;
}
.ltsp-n5 {
    letter-spacing: -5px !important;
}

/* Material icons css */
.md-3 {
    font-size: 3px !important;
}
.md-13 {
    font-size: 13px !important;
}
.md-14 {
    font-size: 14px !important;
}
.md-16 {
    font-size: 16px !important;
}
.md-18 {
    font-size: 18px !important;
}
.md-20 {
    font-size: 20px !important;
}
.md-32 {
    font-size: 32px !important;
}
.md-36 {
    font-size: 36px !important;
}
.md-48 {
    font-size: 48px !important;
}
.h6, h6 {
    font-size: 14px;
}

/* Modal */
#commentModal .modal-dialog {
    max-width: 815px;
}
#commentModal .modal-content, #commentModal .modal-content .h-600 {
    height: 600px;
}
#commentModal .content-body .user-img {
    height: 40px;
}

/* Sidebar */
.logo {
    height: 51px;
}
.navbar {
    justify-content: start;
    align-items: start;
}
.top-osahan-nav-tab {
    z-index: 9;
}
.top-osahan-nav-tab .nav-link {
    text-transform: uppercase;
    letter-spacing: 1px;
}
.navbar .navbar-item{
    width: 100%;
}
.sidebar-nav .navbar-nav a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    color: #000;
    padding: 10px !important;
    border-radius: 5px;
    background: #fff;
    margin-bottom: 9px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.sidebar-nav .navbar-nav .dropdown-menu {
    position: static !important;
    transform: unset !important;
    padding: 0px !important;
    border: none;
    background: transparent;
}
.sidebar-nav .navbar-nav .dropdown-menu a {
    padding: 7px 14px !important;
    font-size: 13px;
    margin: 2px 0px !important;
    border-radius: 8px !important;
}
.sidebar-nav .navbar-nav a:hover, .sidebar-nav .navbar-nav a.active {
    color: #6c757d;
    background: #ececec;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.dropdown-toggle::after {
    margin-left: auto;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #f8f9fa !important;
    border: 2px solid #ff8000;
    border-radius: 7px;
    color: #000 !important;
}
.image-slider .slick-prev {
    z-index: 9;
    left: 20px;
}
.image-slider .slick-next {
    z-index: 9;
    right: 10px;
}
.image-slider img {
    width: 489px;
    height: 600px;
    object-fit: cover;
}

/* Index */
.user-list-item .img-fluid {
    height: 60px;
    width: 60px;
}
.btn-check:active+.btn-outline-primary .follow, .btn-check:checked+.btn-outline-primary .follow {
    display: none;
}
.btn-check:active+.btn-outline-primary .following, .btn-check:checked+.btn-outline-primary .following {
    display: block !important;
}


.pf-item img {
    height: 60px;
}
.pf-item {
    align-items: center;
}
.account-slider .account-item .position-absolute {
    bottom: 4px;
    right: 0;
    left: 0;
}
.feeds .feed-item img.user-img {
    height: 50px;
}

.account-follow .account-item img {
    height: 50px;
}
.langauge-item .btn-language {
    border: 1px solid #dee2e6;
}
.btn-check:active+.btn-language, .btn-check:checked+.btn-language, .btn-language.active, .btn-language.dropdown-toggle.show, .btn-language:active {
    background-color: #ff8000;
    color: #ffffff;
    font-weight: bold;
    border: 1px solid #ff8000 !important;
}
.btn-check:checked+.btn-language span {
    color: #000 !important;
    font-weight: bold !important;
}
.btn-check:focus+.btn, .btn:focus {
    box-shadow: none;
}
#email {
    display: none;
}
.offcanvas.offcanvas-start {
    width: 250px;
}
.comments img {
    height: 36px;
    min-width: 36px;
    width: 36px;
}
.ov-icon {
    font-size: 9px !important;
    padding: 3px !important;
}

/* Profile */
.profile img {
    height: 65px;
}
.followers img {
    height: 25px;
    border: 2px solid #fff;
    margin: 0 -12px 0 0px;
    cursor: pointer;
}

/* Media Css */
@media (max-width: 576px) {
    .m-sm-none {
        display: none !important;
    }
}
@media (max-width: 992px) {
    .lg-none {
        display: none;
    }
    .main-content {
        width: 100% !important;
    }
    .web-none.d-flex.align-items-center.px-3.pt-3 {
        background: #fff;
        border-bottom: 1px solid #dee2e6;
        padding: 0.6rem 1rem !important;
    }
    img.img-fluid.logo-mobile {
        height: 36px;
    }
    button.ms-auto.btn.btn-primary.ln-0 {
        padding: 2px 3px !important;
        width: 33px;
    }
    .col-md-2.ps-0.offcanvas.offcanvas-start.show{
        padding: 0px !important;
    }
}
@media (max-width: 1200px) {
    .m-none {
        display: none !important;
    }
    .main-content {
        width: 75%;
    }
    .side-trend {
        width: 25%;
    }
    .web-none.d-flex.align-items-center.px-3.pt-3 {
        background: #ff8000;
        border-bottom: 1px solid #ff8000;
        padding: 0.6rem 1rem !important;
    }
    img.img-fluid.logo-mobile {
        height: 36px;
    }
    button.ms-auto.btn.btn-primary.ln-0 {
        padding: 2px 3px !important;
        width: 33px;
    }
    .col-md-2.ps-0.offcanvas.offcanvas-start.show{
        padding: 0px !important;
    }
}
@media (min-width: 1200px) {
    .web-none {
        display: none !important;
    }
    .mt-web-5 {
        margin-top: 1.3rem !important;
    }
    .px-web-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .web-none.d-flex.align-items-center.px-3.pt-3 {
        background: #ff8000;
        border-bottom: 1px solid #ff8000;
        padding: 0.6rem 1rem !important;
        width: 100%;
        height: 74px;
    }
    img.img-fluid.logo-mobile {
        height: 36px;
    }
    button.ms-auto.btn.btn-primary.ln-0 {
        padding: 2px 3px !important;
        width: 33px;
    }
    .col-md-2.ps-0.offcanvas.offcanvas-start.show{
        padding: 0px !important;
    }
}
.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link{
    height: 100% !important;
}
.list-group-item.active{
    color: #fff;
    background-color: #ff8000 !important;
    border-color: #ff8000 !important;
}
.form-check .form-check-input{
    float: left;
    margin-left: 0;
    margin-right: 10px;
}
.pollLabel label{
    width: 100%;
    display: block;
}
.chartSection{
    width: 100%;
    max-height: 470px;
    height: 100%;
    background: #fff;
    display: block;
    border: 1px solid #ddd;
    padding: 10px;
    display: flex;
}
.chartSection canvas{
    max-height: 260px;
    height: 100%;
}

.iframeBlock{
    width: 100% !important;
    height: 150px;
    overflow: hidden;
}
.pollOptionLabel .btn{
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;
}
.pollOptionLabel label{
    color: #000;
    box-shadow: 0 1px 4px #00000082;
    background: #fff;
    border: none;
    margin-right: 0;
}
.btn-rounded{
    border-radius: 50px !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    -ms-border-radius: 50px !important;
    -o-border-radius: 50px !important;
}
.font-25{
    font-size: 25px !important;
}
.taggedNeta ion-icon{
    font-size: 25px !important;
}
.mapContainer{
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.showOptions{
    padding: 5px;
    background: #ffffff8c;
    z-index: 999;
    right: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid rgb(146, 146, 146);
    margin-bottom: 5px;
    font-size: 13px;
}
.showOptions .option,.genderRatio .option, .ageGroup .option{
    font-weight: 600;
    font-size: 13px;
}
.showOptions .sqaure, .ageGroup .sqaure{
    width: 40px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333;
    margin-right: 5px;
    color: #fff;
    border-radius: 3px;
    font-size: 13px;
}
.showOptions .color1, .ageGroup .color1{
    background: #333;
}
.showOptions .color2, .ageGroup .color2{
    background: #666;
}
.showOptions .color3, .ageGroup .color3{
    background: #999;
}
.showOptions .color4, .ageGroup .color4{
    background: rgb(196, 196, 196);
}
.genderRatio{
    padding: 5px;
    background: #ffffff8c;
    border-radius: 5px;
    border: 1px solid rgb(146, 146, 146);
    margin-bottom: 5px;
}
.ageGroup{
    padding: 5px;
    background: #ffffff8c;
    border-radius: 5px;
    border: 1px solid rgb(146, 146, 146);
    margin-bottom: 5px;
}
.taggedNeta img{
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}
.referenceList iframe{
    width: 100%;
    margin-right: 10px;
}
.fs3 {
    font-size: .50rem!important;
}
.mapContainer{
    border-radius: 5px;
}
.btn-close{
    border: 2px solid #595959 !important;
}
.btn-close:hover{
    border: 2px solid #595959;
    background: rgb(89 89 89) url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
    color: #fff !important;
}
.closeBtn{
    border: 1px solid red;
    border-radius: 3px;
    color: red !important;
    padding: 3px;
}
.closeBtn:hover{
    background: red;
    color: #fff !important;
    border: 1px solid #fff;
}
.accordion-button:not(.collapsed) {
    color: #ff8000 !important;
    background-color: #ffeee9 !important;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.bg_orange {
    background: #f8ebe2;
    padding: 15px;
    border: 1px solid #ff7f27;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.text-orange {
    color: #ff7f27;
}
.serviceArea, .tegNeta {
    display: inline-flex;
    padding: 0;
    list-style: none;
}
.serviceArea, .tegNeta li {
    padding: 4px 8px;
    border: 1px solid #6c757d;
    border-radius: 25px;
    color: #6c757d;
    text-align: center;
    margin-right: 5px;
}
.btn_social2 {
    padding: 5px;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    display: block;
    line-height: 25px;
    text-align: center;
    background: #6c757d;
    color: #fff;
    margin-left: 7px;
    border: 1px solid #6c757d;
}
.social-icons .navbar-nav.show li:nth-child(1) {
    right: 40px;
}
.social-icons .navbar-nav li {
    position: absolute;
    bottom: 0;
    z-index: -1;
    transition: 0.2s all;
}
.social-icons {
    position: fixed;
    right: 10px;
}
aside{
    height: 80vh;
}
.form-check .form-check-input{
    width: 30px;
    height: 30px;
}
.userNetaTab .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #f8f9fa !important;
    border-bottom: 2px solid #ff8000;
    border-radius: 0px;
    color: #000 !important;
}
.userNetaTab button{
    display: flex;
    justify-content: start;
    align-items: center;
}
.btn-secondary2{
    background: #a7a7a7 !important;
    border: 1px solid #a7a7a7 !important;
    color: #fff !important;
}
.hr-bold{
    height: 2px;
}
.card2{
    border: 1px solid #919191;
    padding: 3px !important;
}
.w-30{
    width: 30%;
}
.w-70{
    width: 70%;
}
.pollLocation{
    width: 120px !important;
    z-index: 9;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    background-color: #ddd;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
}
/* .btn.withIcon{
    display: flex;
    align-items: center;
} */
.btn.withIcon i{
    margin-right: 3px;
}
.regiser a{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}
.pollOptionLabel label{
    display: flex;
    align-items: center;
}




.serviceArea {
    --tag-bg: #ff8d36;
    --tag-hover: #585858;
    --tag-text-color: #FFF;
    --tags-border-color: silver;
    --tag-text-color--edit: #111;
    --tag-remove-bg: var(--tag-hover);
    --tag-pad: .6em 1em;
    --tag-inset-shadow-size: 1.3em;
    --tag-remove-btn-bg--hover: black;
    display: inline-block;
    min-width: 0;
    border: none;
    margin-left: -15px;
    margin-bottom: 5px;
}

.serviceArea .tagify__tag {
    margin-top: 0;
    min-width: 70px;
    width: auto;
    padding: 0;
    margin-right: 10px;
}
.tagify__tag__removeBtn{
    font-size: 25px;
    color: #fff !important;
    margin-right: 5px;
}
.serviceArea .tagify__tag > div {
    border-radius: 25px;
    padding: 3px 10px;
}

.addServiceArea {
    color: #ff8d36 !important;
    font-size: 35px;
    font-weight: 600;
    border: 0;
    background: none;
    box-shadow: 0 0 0 2px inset currentColor;
    border-radius: 5px !important;
    padding: 5px 10px !important;
    width: auto;
    height: auto;
    line-height: 25px;
    cursor: pointer;
    outline: none;
    transition: 0.1s ease-out;
    margin: 0 5px 0 5px;
    vertical-align: top;
}

.addServiceArea:hover {
    box-shadow: 0 0 0 3px inset currentColor;
}
/* .tagify__tag__removeBtn:hover{
    background: #fff;
    color: #333 !important;
} */
.serviceArea .tagify__input {
    display: none;
}
.cSelect{
    padding: 7px 20px 7px 7px  !important;
}
.btn-check:active+.btn-outline-primary .follow, .btn-check:checked+.btn-outline-primary .follow {
    display: none;
}
.btn-check:active+.btn-outline-primary .following, .btn-check:checked+.btn-outline-primary .following {
    display: block !important;
}
.fileUpload {
    position: relative;
    cursor: pointer;
    padding: 7px 15px;
    display: block;
}
.fileUpload i{
    cursor: pointer;
}

.fileUpload > input[type='file'] {
    display: none;
}
.w-90{
    width: 90%;
}
.form-switch .form-check-input{
    width: 3rem !important;
}
.imgPreview{
    object-fit: cover;
    width: 100%;
    height: auto;
}
.image-upload {
    margin: auto;
    width: 200px;
    height: 50px;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
.scrollList{
    overflow: auto;
    height: 300px;
}
.stickyHeader{
    text-align: center;
    margin: 0;
    padding: 5px;
    border: 1px solid #ddd;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9;
    background: #fff;
}
.offcanvas-end{
    top: 0;
    right: 0;
    width: 250px !important;
    border-left: 1px solid rgba(0,0,0,.2);
}

main{
    position: relative;
}
/* .row{
    --bs-gutter-x: 0 !important;
} */
aside.sidebarClose{
    background: #fff;
    width: 4%;
    padding: 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    margin-right: 5px;
    margin-left: 10px;
    position: absolute;
    height: 100vh;
    transition: 0.3s ease all;
}
aside.sidebarOpen{
    width: 20% !important;
}
aside.sidebarClose .menuName{
    display: none;
}
.mainSec{
    width: 96% !important;
    margin-left: 4%;
}
.fa-lg{
    line-height: 1 !important;
}
.sidebarArrow{
    position: fixed;
    left: -20px;
    background: #ddd;
    padding: 2px 5px 5px 23px;
    z-index: 999;
    border-radius: 0 50px 50px 0;
    border: none;
    width: 55px;
    height: 40px;
    bottom: 2%;
    box-shadow: 0 0 5px #0000006b;
    transition: all 0.2s ease-in-out;
}
.sidebarArrow:hover{
    width: 60px;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}
.sidebarArrow img{
    width: 20px;
}
.rotateMenu{
    transform: rotate(180deg);
    transition: all 0.2s ease-in 0s;
    border-radius: 50px;
    width: 40px;
    left: -20px;
    padding: 2px 5px 5px 11px;
}
.react-autosuggest__container{
    width: 95%;
}
.react-autosuggest__input{
    width: 100%;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.react-autosuggest__suggestions-list{
    position: absolute;
    background: #fff;
    width: 50%;
    z-index: 99;
    box-shadow: 0 3px 5px #00000026;
    border-radius: 0 0 5px 5px;
    list-style: none;
    padding-left: 0;
}
.react-autosuggest__suggestions-list li{
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
.searchbar .searchbarBlock{
    width: 100%;
    display: flex;
}
.searchbar .searchbarBlock .material-icons{
    width: 5%;
}
.react-datepicker__input-container  input{
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    color: #333;
}
.react-datepicker__input-container .react-datepicker__calendar-icon{
    position: absolute;
    top: 10px;
    left: 5px;
    padding: 0 !important;
}
.form-check-input{
    margin-top: 0 !important;
}


/* new */
#postModal.active{
    height: 590px;
  }
#postModal .wrapper{
    width: 100%;
    display: flex;
  }
  #postModal .wrapper section{
    width: 100%;
    background: #fff;
  }
  #postModal .wrapper .audience{
    display: none;
  }
  #postModalimg{
    cursor: pointer;
  }
  #postModal .post header{
    font-size: 22px;
    font-weight: 600;
    padding: 17px 0;
    text-align: center;
    border-bottom: 1px solid #bfbfbf;
  }
  #postModal .post form{
    margin: 20px 25px;
  }
  #postModal .post form .content,
  .audience .list li .column{
    display: flex;
    align-items: center;
  }
  #postModal .post form .content img{
    cursor: default;
    max-width: 52px;
  }
  #postModal .post form .content .details{
    margin: -3px 0 0 12px;
  }
  #postModal form .content .details p{
    font-size: 17px;
    font-weight: 500;
  }
  #postModal .content .details .privacy{
    display: flex;
    height: 25px;
    cursor: pointer;
    padding: 0 10px;
    font-size: 11px;
    margin-top: 3px;
    border-radius: 5px;
    align-items: center;
    max-width: 98px;
    background: #E4E6EB;
    justify-content: space-between;
  }
  .details .privacy span{
    font-size: 13px;
    margin-top: 1px;
    font-weight: 500;
  }
  #postModal .details .privacy i:last-child{
    font-size: 13px;
    margin-left: 1px;
  }
  #postModal form :where(textarea, button){
    width: 100%;
    outline: none;
    border: none;
  }
  #postModal form textarea{
    resize: none;
    font-size: 18px;
    margin-top: 30px;
    min-height: 50px;
  }
  #postModal form textarea::placeholder{
    color: #858585;
  }
  #postModal form textarea:focus::placeholder{
    color: #b3b3b3;
  }
  #postModal form :where(.theme-emoji, .options){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #postModal .theme-emoji img:last-child{
    max-width: 24px;
  }
  #postModal form .options{
    height: 45px;
    margin: 15px 0;
    padding: 0 15px;
    border-radius: 7px;
    border: 1px solid #B9B9B9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  #postModal form .options :where(.list, li),
  .audience :where(.arrow-back, .icon, li .radio){
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #postModal form .options p{
    color: #595959;
    font-size: 15px;
    font-weight: 500;
    cursor: default;
  }
  #postModal form .options .list{
    list-style: none;
    margin: 0;
  }
  #postModal p{
    margin: 0;
  }
  #postModal .options .list li{
    height: 42px;
    width: 42px;
    margin: 0 -1px;
    cursor: pointer;
    border-radius: 50%;
  }
  #postModal .options .list li:hover{
    background: #f0f1f4;
  }
  #postModal .options .list li img{
    width: 23px;
  }
  #postModal form button{
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.3s ease;
  }
  #postModal form textarea:valid ~ button{
    color: #fff;
    cursor: pointer;
    background: #4599FF;
  }
  #postModal form textarea:valid ~ button:hover{
    background: #1a81ff;
  }
  #postModal .container .audience{
    opacity: 0;
    transition: opacity 0.12s ease;
  }
  #postModal .container.active .audience{
    opacity: 1;
  }
  #postModal .audience header{
    padding: 17px 0;
    text-align: center;
    position: relative;
    border-bottom: 1px solid #bfbfbf;
  }
  #postModal .audience header .arrow-back{
    position: absolute;
    left: 25px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    font-size: 15px;
    color: #747474;
    border-radius: 50%;
    background: #E4E6EB;
  }
  #postModal .audience header p{
    font-size: 22px;
    font-weight: 600;
  }
  #postModal .audience .content{
    margin: 20px 25px 0;
  }
  #postModal .audience .content p{
    font-size: 17px;
    font-weight: 500;
  }
  #postModal .audience .content span{
    font-size: 14px;
    color: #65676B;
  }
  #postModal .audience .list{
    margin: 15px 16px 20px;
    list-style: none;
  }
  #postModal .audience .list li{
    display: flex;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 12px 10px;
    border-radius: 7px;
    align-items: center;
    justify-content: space-between;
  }
  #postModal .list li.active,
  #postModal .audience .list li.active:hover{
    background: #E5F1FF;
  }
  #postModal .audience .list li:hover{
    background: #f0f1f4;
  }
  #postModal .audience .list li .column .icon{
    height: 50px;
    width: 50px;
    color: #333;
    font-size: 23px;
    border-radius: 50%;
    background: #E4E6EB;
  }
  #postModal .audience .list li.active .column .icon{
    background: #cce4ff;
  }
  #postModal .audience .list li .column .details{
    margin-left: 15px;
  }
  #postModal .list li .column .details p{
    font-weight: 500;
  }
  #postModal .list li .column .details span{
    font-size: 14px;
    color: #65676B;
  }
  #postModal .list li .radio{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: relative;
    border: 1px solid #707070;
  }
  #postModal .list li.active .radio{
    border: 2px solid #4599FF;
  }
  #postModal .list li .radio::before{
    content: "";
    width: 12px;
    height: 12px;
    border-radius: inherit;
  }
  #postModal .list li.active .radio::before{
    background: #4599FF;
  }

.pollOptions button{
    width: auto !important;

}


.refCard {
    min-height: 10rem;
    padding: 5px;
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.2s ease-in-out;
}
.refCard:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.refCard:hover .card-actions {
    transform: translateY(0);
    transition-timing-function: ease-out;
    transition-delay: 128ms;
}
  
.card-actions {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    padding: 1rem;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50px;
    transform: translateY(100%);
    transition: transform 128ms ease-in;
}
.taggedList{
    background: #e9e9e9;
}
.commitedList{
    background: #badbff;
}
.solvedList{
    background: #d8ffe0;
}
.commitedList .stickyHeader,.commitedList .list-group-item, .solvedList .stickyHeader,.solvedList .list-group-item, .taggedList .stickyHeader,.taggedList .list-group-item{
    background: transparent;
}
.netaProfileTable tbody tr a, .userProfileTable tbody tr a{
    display: contents;
    color: #333;
}
.netaProfileTable tbody tr{
    border-bottom: 1px solid #ddd;
}
#byNeta .accordion-collapse{
    min-height: 250px;
    height: 250px;
    overflow: auto;
}
.mobileOverlay{
    overflow: hidden;
    background: #00000026;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}