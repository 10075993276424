@media only screen and (max-width:767px){
    .mobile_sidebar .close {
        position: absolute;
        left: -50px;
        color: #fff !important;
        background: #333;
        opacity: 1;
        text-shadow: none;
        box-shadow: 0 0 10px #00000059;
        display: block;
    }
    .mobile_sidebar {
        position: fixed;
        width: 225px;
        background: #fff;
        color: #333;
        right: -300px;
        top: 0;
        bottom: 0;
        box-shadow: 0 0 5px #00000059;
        padding: 50px 0;
        z-index: 9;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
    }
    .mobile_sidebar.open_menu {
        right: 0;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
    }
    .m_w_100{
        width: 100% !important;
    }
    .chartSection{
        height: auto;
        display: block !important;
        max-height: unset;
    }
    aside.sidebarOpen {
        width: 100% !important;
        position: fixed;
        left: 0;
        z-index: 9;
        margin: 0;
        height: 100vh;
        top: 58px;
        left: 0 !important;
        transition: 0.3s ease all;
    }
    aside.sidebarClose{
        background: #fff;
        width: 4%;
        padding: 5px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        margin-right: 5px;
        margin-left: 0;
        left: -50px;
        position: fixed;
        height: 100vh;
        top: 58px;
        transition: 0.3s ease all;
        z-index: 9;
    }
    aside.sidebarClose .menuName{
        display: none;
    }
    aside.sidebarClose .menuName.menuNameShow{
        display: block !important;
    }
    .mainSec, .main{
        width: 100% !important;
        margin-left: 0 !important;
    }
    .bg-white{
        overflow: auto;
    }
}


@media only screen and (min-width:768px) and (max-width:1024px){

}


@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {

}
